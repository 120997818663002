<template>
  <div class="app-container">
    <van-cell-group class="block">
      <van-cell
        is-link
        class="cell"
        @click="$router.push({ name: 'CashCard' })"
        v-for="(item, index) in dataList"
        :key="index"
      >
        <!-- 使用 title 插槽来自定义标题 -->
        <template #title>
          <div class="bank-main">
            <svg class="icon" aria-hidden="true" v-if="item.bank_id === 123">
              <use xlink:href="#van-icon-jue-yinhanglogo-5"></use>
            </svg>
            <svg class="icon" aria-hidden="true" v-if="item.bank_id === 124">
              <use xlink:href="#van-icon-jue-yinhanglogo-6"></use>
            </svg>
            <svg class="icon" aria-hidden="true" v-if="item.bank_id === 125">
              <use xlink:href="#van-icon-jue-yinhanglogo-1"></use>
            </svg>
            <div class="bank-detail">
              <div class="name">
                {{ $store.state.config.bankInfo[item.bank_id].label }}
              </div>
              <div class="desc">卡号：{{ item.card_no }}</div>
            </div>
          </div>
        </template>
        <template #right-icon>
          <van-icon name="arrow" />
        </template>
      </van-cell>
    </van-cell-group>

    <van-cell-group class="block">
      <div class="cell">
        <div class="cell-title">提现金额</div>
      </div>
      <van-field
        autofocus
        clearable
        v-model.number="amount"
        label-width="20"
        label="￥"
        type="number"
        placeholder="请输入金额"
      />
      <div class="cell">
        <div class="desc">
          可提现余额 {{ userInfo.balance / 100 }} 元, 未解冻金额
          {{ userInfo.take_balance / 100 }} 元
        </div>
      </div>
    </van-cell-group>
    <van-cell-group class="block" style="padding: 0 20px">
      <van-button type="info" block @click="getUp"
        >预计两个工作日内到账，确定提现</van-button
      >
    </van-cell-group>
  </div>
</template>
<script>
import { Notify } from 'vant'
export default {
  data () {
    return {
      dataList: {},
      form: {},
      userInfo: {},
      amount: ''
    }
  },
  mounted () {
    this.getBankInfo()
    this.getUserInfo()

  },
  methods: {
    getBankInfo () {
      this.$axios.post('/wxc/card/lists', {
        count: 1
      }).then(res => {
        this.dataList = res.data.list
      })
    },
    getUserInfo () {
      this.$axios.post('/wxc/client/detail').then(res => {
        this.userInfo = res.data
      })
    },
    getUp(){
      let num = Number(this.amount)
      if(num < 100){
        Notify({ type: 'danger', message: '提现金额 >= 100元' })
        return false
      }
      this.$axios.post('/wxc/balance/withdraw', {
        card_id: this.dataList[0].card_id,
        amount: num
      }).then(res => {
        if(res.code === 200){
          this.$router.push({name: 'CashRecord'})
        }else{
          Notify({ type: 'danger', message: res.msg })
        }
      })


      
    }
  }
}
</script>
<style lang="scss" scoped>
.block {
  .head-title {
    margin: 0;
    padding: 20px 16px 16px;
    color: rgba(69, 90, 100, 0.6);
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
  }
}

.cell {
  display: flex;
  align-items: center;
  .desc {
    font-size: 12px;
    color: rgba(0, 0, 0, 0.5);
  }
}

.bank-main {
  display: flex;
  align-items: center;
  .icon {
    width: 40px;
    height: 40px;
  }
  .bank-detail {
    padding: 0 10px;
    .name {
      font-size: 16px;
      font-weight: 600;
    }
    .desc {
      font-size: 12px;
      color: rgba(69, 90, 100, 0.6);
    }
  }
}
</style>